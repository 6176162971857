import React from 'react';

export default function Header() {
    const mdev = "<mdev />"
    return (
        <div className="header">
            <ul>
                <li><a href="/">about</a></li>
                <li><a href="/">portfolio</a></li>
                <a href="/"><h1>{mdev}</h1></a>
                <li><a href="/">pricing</a></li>
                <li><a href="/contact">contact</a></li>
            </ul>
            <div className="divider">

            </div>
        </div>
    )
}