import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import './App.css';
import './styles/Header.css';
import './styles/Main.css';
import './styles/ContactUs.css';
import Header from './components/Header';
import Main from './components/Main';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Portfolio from "./components/Portfolio";

function App() {
  return (
      <Router>

         <Switch>
            <Route exact path="/">
              <Header />
              <Main />
              <Footer />
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/contact">
              <Header />
              <ContactUs />
              <Footer />
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/portfolio">
              <Header />
              <Portfolio />
              <Footer />
            </Route>
          </Switch>

      </Router>
  );
}

export default App;

