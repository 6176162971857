import React from 'react';

export default function Main() {
    return(
        <div className="mainContainer">

            <img className="backgroundImage" src="https://picsum.photos/1000/1000" alt="" />

            <div className="landing">
                <div className="landingLeft">
                    <h1>Responsive, professional websites.</h1>
                    <h3>Priced for small businesses and freelancers.</h3>
                </div>
                <div className="landingRight">
                    <img src='https://picsum.photos/600/500' alt='' />
                </div>
            </div>

            <div className="dividerWindow"></div>

            <div className="landing2">
                <div className="landing2Left">
                    <img src='https://picsum.photos/600/500' alt='' />
                </div>
                <div className="landing2Right">
                    <h1>Don't compromise on your online presence.</h1>
                    <h3>Find the plan that's right for you or your business.</h3>
                </div>
            </div>
        </div>

    )
}