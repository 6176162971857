import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactUs () {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_huq4jgk', 'template_gtrsyng', form.current, 'user_5WpVGmRdAFWIhWYVHk2PV')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
      <div id="contactContainer">
        <form ref={form} onSubmit={sendEmail}>
            <input placeholder="name" type="text" name="user_name" />
            <input placeholder="email" type="email" name="user_email" />
            <textarea placeholder="message" name="message" />
            <input type="submit" value="Send" />
        </form>
      </div>
    
  );
};